import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { contents } from '../../contents/landing';
import { Chart } from 'angular-highcharts';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs';
import { Partner } from '../../model/partner';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../../config/ConfigService';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  partner: Partner;
  chart: any;
  showText = 'Show More';
  showMore = false;
  wowSubscription: Subscription;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private wowService: NgwWowService,
    private titleService: Title,
    private configService: ConfigService) {
    // Reload WoW animations when done navigating to page,
    // but you are free to call it whenever/wherever you like
    this.route.params.subscribe(params => {
      this.setCurrentPartnerContent(params.ifaname);
      this.titleService.setTitle(this.partner.name);
    });
  }

  ngAfterViewInit() {
    this.wowService.init();
  }

  ngOnInit() {
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item: HTMLElement) => {
        this.initializeGraph();
        this.wowSubscription.unsubscribe();
      });
  }

  setCurrentPartnerContent(user) {
    const content = contents[user];
    if (!content) {
      this.router.navigateByUrl('/page/404');
    } else {
      this.partner = content;
      this.initializeForm();
    }
  }

  initializeForm() {

  }

  initializeGraph() {
    this.chart = new Chart({
      chart: {
        type: 'column',
        height: 480
      },
      title: {
        text: ''
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 600
          },
          chartOptions: {
            chart: {
              className: 'small-chart'
            }
          }
        }]
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ['5.00%', '7.50%', '10.00%', '12.00%', '15.00%'],
        labels: {
          style: {
            fontSize: '16px',
            fontWeight: '700',
            color: 'rgba(0,0,0,0.4)'
          }
        },
        title: {
          text: 'Rate of return',
          style: {
            fontSize: '18px',
            fontWeight: '700',
            color: 'rgba(0,0,0,0.5)',
            marginTop: '20px'
          }
        }
      },
      legend: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'Absolute return after 25 years',
          style: {
            fontSize: '18px',
            fontWeight: '700',
            color: 'rgba(0,0,0,0.5)',
            marginTop: '20px'
          }
        },
        min: 0,
        labels: {
          step: 2,
          style: {
            fontSize: '16px',
            fontWeight: '600',
            color: 'rgba(0,0,0,0.4)'
          },
          formatter() {
            return `${this.pos * 3}%`;
          }
        }
      },
      tooltip: {
        formatter() {
          return '<b>' + this.x + '</b> is <b>' + this.y + ' Lacs</b>';

        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            format: '{y} <br> Lacs',
            style: {
              fontSize: '14px',
              textAlign: 'center'
            }
          },
          pointPadding: 0.22
        }
      },
      series: [
        {
          type: 'column',
          data: [{ y: 59.8 }, { y: 88.3 }, { y: 133.8 }, { y: 189.8 }, { y: 328.4 }],
          color: '#3d42ec'
        }
      ]
    });
  }

  toggleComparison(target) {
    this.showMore = !this.showMore;
    if (this.showMore) {
      this.showText = 'Show Less';
    } else {
      this.showText = 'Show More';
      target.scrollIntoView();
    }
  }

  enableCustomerForm(): boolean {
    return this.configService.isCustomerFormEnabled();
  }

  webinarSectionEnabled(){
    return this.partner.webinarSectionEnabled;
  }
}
