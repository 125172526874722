import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LandingComponent} from './sections/landing/landing.component';
import {NotfoundComponent} from './sections/notfound/notfound.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ComparisonPopupComponent} from './sections/comparison-popup/comparison-popup.component';
import {ChartModule} from 'angular-highcharts';
import {NgwWowModule} from 'ngx-wow';
import { HttpClientModule } from '@angular/common/http';
import {CustomerContactFormComponent} from './sections/landing/customer-contact-form/customer-contact-form.component';
import {ConfigService} from './config/ConfigService';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotfoundComponent,
    ComparisonPopupComponent,
    CustomerContactFormComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ChartModule,
    NgwWowModule,
    ModalModule.forRoot(),
    HttpClientModule
  ],
  providers: [
    ConfigService
  ],
  entryComponents: [
    ComparisonPopupComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
