const contents = {
    demo: {
        name: 'Demo',
        phone: '9999999999',
        email: 'abc@example.com',
        investmentUrl: 'https://finzy.com/invest',
        logo: 'assets/images/partner_logos/placeholder.png',
        webinarSectionEnabled: true,
    },
    finzy: {
        name: 'Finzy',
        phone: '9999999999',
        email: 'tech@finzy.com',
        investmentUrl: 'https://finzy.com/invest',
        formsparkFormId: '8NxBjDGfGxQbSSBz0JhmC',
        webinarSectionEnabled: false
    },
    'sharath-mascarenhas': {
        name: 'Ferdinand Wealth Management',
        phone: '9886761207',
        email: 'shobhita.mishra@gmail.com',
        formsparkFormId: 'hz2usf-_1KDHrqXsrKA-l',
        investmentUrl: 'https://finzy.com/lenderintro?partner=SHO205',
        logo: 'assets/images/partner_logos/sharathmascarenhas.png',
        logoClass: 'ferdinand-logo',
        webinarSectionEnabled: true
    },
    rupeeboss: {
        name: 'Rupee Boss Financial Services',
        phone: '8356844040',
        email: 'sachin.rane@rupeeboss.com',
        formsparkFormId: 'X40jYed8BqB6bf0CxsRdi',
        investmentUrl: 'https://finzy.com/lenderintro?partner=RUP568',
        logo: 'assets/images/partner_logos/rupeeboss.png',
        logoClass: 'rupeeeboss-logo',
        webinarSectionEnabled: false
    },
    heetinvestments: {
        name: 'Heet Investments',
        phone: '8711934989',
        email: 'bpnitin@gmail.com',
        formsparkFormId: '4up0FxoM43KXHOjkxdSWX',
        investmentUrl: 'https://finzy.com/lenderintro?partner=NIT895',
        logo: 'assets/images/partner_logos/heet.png',
        logoClass: 'heet-logo',
        webinarSectionEnabled: false
    },
    alexus: {
        name: 'Alexus Capital Management',
        phone: '9825300447',
        email: 'kaushalmandalia@gmail.com',
        formsparkFormId: 'LgAsZ35hdOOmTPaWu-uX3',
        investmentUrl: 'https://finzy.com/lenderintro?partner=MAN332',
        logo: 'assets/images/partner_logos/alexus.png',
        logoClass: 'alexus-logo',
        webinarSectionEnabled: false
    },
    fundsumo: {
        name: 'FundSumo',
        phone: '9480609330',
        email: 'sameer.navaratna@gmail.com',
        formsparkFormId: '-2-J3bAGXakvHnrE2CZzm',
        investmentUrl: 'https://finzy.com/lenderintro?partner=SAM507',
        logo: '',
        logoClass: 'fundsumo-logo',
        webinarSectionEnabled: false
    },
    rischwealth: {
        name: 'Risch Wealth',
        phone: '9448090998',
        email: 'rischwealth@gmail.com',
        formsparkFormId: 'zn3hTeQ3BDOWpUR7bqcAi',
        investmentUrl: 'https://finzy.com/lenderintro?partner=RIS310',
        logo: '',
        logoClass: 'rischwealth-logo',
        webinarSectionEnabled: false
    },
    triwealth: {
        name: 'TriWealth Managers and Insurance Marketing LLP',
        phone: '9929145054 , 9887456400',
        email: 'info@triwealthindia.com',
        logo: '/assets/images/partner_logos/triwealth.png',
        logoClass: 'triwealth-logo',
        investmentUrl: 'https://finzy.com/lenderintro?partner=TRI614',
        webinarSectionEnabled: false
    },
    vnbinternational: {
        name: 'VNB International',
        phone: '9371025233',
        email: 'nayan@vnbindia.com',
        logo: '/assets/images/partner_logos/vnb.png',
        logoClass: 'vnb-logo',
        investmentUrl: 'https://finzy.com/lenderintro?partner=NAY518',
        webinarSectionEnabled: false
    },
    estimoola: {
        name: 'Estimoola Consulting Services Pvt. Ltd',
        phone: '9606312919',
        email: 'support@ecsmf.com',
        logo: '/assets/images/partner_logos/estimoola.png',
        logoClass: 'estimoola-logo',
        investmentUrl: 'https://finzy.com/lenderintro?partner=EST321',
        webinarSectionEnabled: false
    }
};
export { contents };
