<div class="modal-header">
    <h2 class="modal-title text-center">Comparison of Mutual Fund with P2P
    </h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <td></td>
                    <td><strong>Mutual Fund (AMC)</strong></td>
                    <td><strong> Platforms</strong></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Regulator</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        Registration Requirement
                    </td>
                    <td>Registration</td>
                    <td>
                        NBFC-P2P Registration
                    </td>
                </tr>
                <tr>
                    <td>
                        Investment
                    </td>
                    <td>
                        Units of Scheme of AMC
                    </td>
                    <td>
                        Direct share of an unsecured loan – personal or business
                    </td>
                </tr>
                <tr>
                    <td>
                        Risk
                    </td>
                    <td>
                        Equity Price Risk,
                        Credit Risk and
                        Interest Rate Risk
                    </td>
                    <td> Risk </td>
                </tr>
                <tr>
                    <td>Who bears the risk
                    </td>
                    <td>
                        Investor
                    </td>
                    <td>

                        Investor </td>
                </tr>
                <tr>
                    <td>Investment Themes
                    </td>
                    <td>
                        Debt or Equity or Balanced. Thematic based on scheme investment thesis
                    </td>
                    <td>
                        Purely debt. Investor can choose individual loans or portfolios with low, moderate or high risk
                        exposure based on their risk appetite </td>
                </tr>
                <tr>
                    <td>Investment Themes
                    </td>
                    <td>
                        Debt or Equity or Balanced. Thematic based on scheme investment thesis
                    </td>
                    <td>
                        Purely debt. Investor can choose individual loans or portfolios with low, moderate or high risk
                        exposure based on their risk appetite </td>
                </tr>
                <tr>
                    <td>Performance Tracking
                    </td>
                    <td>
                        NAV published on daily basis and monthly fact sheets </td>
                    <td>
                        Real time dashboard for Investors to track portfolio performance. Delay and NPA data updated on
                        a daily basis.</td>
                </tr>
                <tr>
                    <td>Minimum Investment Limit
                    </td>
                    <td>
                        No/Very Low Limit</td>
                    <td>
                        Platforms have different minimum investment amount based on positioning. </td>
                </tr>
                <tr>
                    <td>Maximum Investment Limit
                    </td>
                    <td>
                        No Upper Limit</td>
                    <td>
                        RBI has capped investment under one PAN at INR 50 lakhs. </td>
                </tr>
                <tr>
                    <td>Investment Concentration Limit
                    </td>
                    <td>
                        No Limit</td>
                    <td>
                        RBI has capped maximum investment in one loan at INR 50,000. Platforms follow their own
                        algorithm within the cap to limit concentration into a few loans. Investment in a loan can be
                        between INR 500 to INR 50,000. </td>
                </tr>
                <tr>
                    <td>
                        Exit

                    </td>
                    <td>
                        Open ended schemes: As and when needed
                        Close ended/FMPs: Only on maturity of scheme</td>
                    <td>
                        Returns come in form of EMIs – combination of principal and interest. Investor can take the
                        monthly EMI to bank account or reinvest only principal, only interest or both. </td>
                </tr>
                <tr>
                    <td>
                        Liquidity

                    </td>
                    <td>
                        Open ended schemes can be liquidated on T+2 or T+3 days basis</td>
                    <td>
                        Returns come on monthly basis and investment in completely repaid at the end of tenure. Some
                        platforms offer liquidity on best effort basis, provided they can find a buyer for underlying
                        loans. </td>
                </tr>
                <tr>
                    <td>
                        Taxation
                    </td>
                    <td>
                        Capital Gains. Rules differ based on Short Or Long term for Debt/Equity Schemes. Tax free to
                        marginal rate of tax applicable</td>
                    <td>
                        Interest income is taxed as Income from Other Sources at marginal rate of tax applicable. </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-custom" (click)="bsModalRef.hide()">Go Back</button>
</div>