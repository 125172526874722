import {Component, Input} from '@angular/core';
import {Partner} from '../../../model/partner';
import {ConfigService} from '../../../config/ConfigService';

@Component({
  selector: 'app-customer-contact-form',
  templateUrl: './customer-contact-form.component.html',
  styleUrls: ['customer-contact-form.component.scss']
})
export class CustomerContactFormComponent {
  @Input() partner: Partner;

  constructor(private configService: ConfigService) {
  }

  getActionUrl() {
    return `https://submit-form.com/${this.partner.formsparkFormId}`;
  }

  getSubject() {
    return `New form submission from ${this.configService.getSiteName()}`;
  }

  getCurrentUrl() {
    return window.location.href;
  }
}
