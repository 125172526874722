import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable()
export class ConfigService {
  public isCustomerFormEnabled(): boolean {
    return environment.showCustomerForm;
  }

  public getSiteName(): string {
    return environment.siteName;
  }
}
