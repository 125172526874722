<section class="banner">
  <div class="content">
    <div class="container">
      <div class="row">
          <img class="partner-logo {{partner.logoClass}}" src={{partner.logo}}/>
      </div>
      <div class="row">
        <div class="col-md-9">
          <h1>We bring <br>
            to you the new revolutionary <br>
            asset class - Peer to Peer Lending (P2P)</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="intro py-100">
  <div class="container">
    <div class="sec-heading text-center">
      <h2>Why Consider a New Asset Class </h2>
    </div>
    <div class="row">
      <div class="col-md-4 wow fadeInLeft">
        <div class="content-item-3 pl-0">
          <div class="item-head">
            <img src="assets/images/icons/intro/asset-allocation.svg" class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Asset Allocation demands distribution across asset classes</h3>
          </div>
          <p>Diversification across multiple asset classes is one of the most effective risk management tools
            available to Investors. Studies have shown that over 90% of the variations in a portfolio’s
            return can be attributed to the asset allocation decision.
          </p>
        </div>
      </div>
      <div class="col-md-4 wow fadeInUp">
        <div class="content-item-3">
          <div class="item-head">
            <img src="assets/images/icons/intro/uncorrelated.svg" class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Uncorrelated <br>Assets</h3>
          </div>
          <p>Different asset classes at times respond differently to market conditions and changes in the
            economic environment. Hence, lower the correlation in the returns of the assets on your
            portfolio, steadier the overall return on your portfolio.
          </p>
        </div>
      </div>
      <div class="col-md-4 wow fadeInRight">
        <div class="content-item-3 pr-0 mb-0">
          <div class="item-head">
            <img src="assets/images/icons/intro/alternate-and-new-asset.svg"
                 class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Alternate and<br> New Asset</h3>
          </div>
          <p>P2P Lending is a new asset class that you should consider to add to your portfolio. It gives you
            access to earn EMIs from retail Borrowers. P2P lending is uncorrelated to the other asset
            classes and is not linked directly to market variations.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="what-is-p2p py-100 bg-grey">
  <div class="container">
    <div class="sec-heading text-center">
      <h2>What is P2P Lending</h2>
      <h6>P2P lending is a new asset class that provides an opportunity for Investors to invest in multiple loans
        of credit assessed Borrowers. P2P lending companies are digital platforms that act as a facilitator in
        the complete management of the loan life cycle</h6>
    </div>
    <div class="row">
      <div class="col-md-4 wow fadeInLeft">
        <div class="content-item-3 pl-0">
          <div class="item-head">
            <img src="assets/images/icons/what-is-p2p/p-2-p-lending-is-a-digital-marketplace-model.svg"
                 class="img-fluid d-inline-block">
            <h3 class="d-inline-block">P2P Lending is a Digital Marketplace Model</h3>
          </div>
          <p>P2P Platforms are an online marketplace that allow Investors and Borrowers to transact and
            leverage the power of shared economy.P2P lending platforms do not lend their own funds but act
            as facilitators to both the Borrower and the lender.
          </p>
        </div>
      </div>
      <div class="col-md-4 wow fadeInUp">
        <div class="content-item-3">
          <div class="item-head">
            <img src="assets/images/icons/what-is-p2p/consumer-to-consumer-platform.svg"
                 class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Consumer to Consumer Platform</h3>
          </div>
          <p>P2P platforms are a c2c marketplace connecting Investors on one side of the spectrum with
            qualified Borrowers on the other side thus providing powerful value proposition for both
            stakeholders.
          </p>
        </div>
      </div>
      <div class="col-md-4 wow fadeInRight">
        <div class="content-item-3 pr-0 mb-0">
          <div class="item-head">
            <img src="assets/images/icons/what-is-p2p/many-to-many-platform.svg"
                 class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Many to Many<br> Platform</h3>
          </div>
          <p>P2P platforms provide an opportunity to an Investor to invest in multiple loans and helps
            diversify risks, and a Borrower’s loan also gets filled by multiple Investors thereby acting as
            a many to many marketplace platform.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="why-p2p py-100">
  <div class="container">
    <div class="sec-heading text-center">
      <h2>Why P2P Lending</h2>
      <h6>There is a big opportunity to provide financial access to a larger base of creditworthy consumers in
        India. P2P lending was provided investors to top into this opportunity to earn returns. P2P lending in
        India is regulated by Reserve Bank of India & only the companies who qualify to the stringent Regulatory
        guidelines are allowed to operate with NBFC – P2P licence</h6>
    </div>
    <div class="row">
      <div class="col-md-6 wow fadeInLeft">

        <div class="sec-body">
          <div class="content-item-3-v">
            <h3>Earn regular income as high as 2.5 times of that fixed deposit</h3>
            <p>P2P lending allows Investors to earn monthly returns in the form of EMIs. These monthly
              returns can be treated as secondary source of an income by the Investor and certain P2P
              platforms allow you to automatically reinvest into new loans at a click of button to unlock
              the power of compounding.</p>
          </div>
          <div class="content-item-3-v">
            <h3>Fills a wide vacuum between fixed deposit and Equity</h3>
            <p>Debt and equity asset classes thereby providing a great flavor to the Investors portfolio.
              P2P lending as an asset class is also uncorrelated to market volatility and brings balance
              to the overall portfolio returns of your investment.</p>
          </div>
          <div class="content-item-3-v">
            <h3>Access to Retail Assets</h3>
            <p>Before the introduction of P2P lending as an asset class, an individual Investor did not have
              an easy option to directly invest in retail loans. With P2P lending today, an individual
              Investor has the access to invest in retail loans which is a powerful investment that most
              FI allover the world are investing in.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 p-0 wow fadeInRight">
        <img src="assets/images/banners/group-14@2x.jpg" class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="comparison py-100 bg-grey" #comparisonTarget>
  <div class="container text-center">
    <div class="sec-heading">
      <h2>Quick Analysis - Mutual Fund and P2P Lending
      </h2>
    </div>
    <div class="row">
      <div class='col-md-12'>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Parameters</th>
              <th>Mutual Fund (AMC)</th>
              <th>P2P Platforms</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Regulator</td>
              <td>Securities and Exchange Board of India (SEBI)</td>
              <td>Reserve Bank of India (RBI)</td>
            </tr>
            <tr>
              <td>
                Registration Requirement for the Asset Class
              </td>
              <td>AMC Registration from SEBI</td>
              <td>
                NBFC-P2P Registration from RBI
              </td>
            </tr>
            <tr>
              <td>
                Investment
              </td>
              <td>
                Units of Scheme of AMC
              </td>
              <td>
                Direct share of an unsecured personal loan
              </td>
            </tr>
            <tr>
              <td>
                Risk
              </td>
              <td>
                Equity Price Risk,
                Credit Risk and
                Interest Rate Risk
              </td>
              <td>Credit Risk</td>
            </tr>
            <tr>
              <td>Who bears the risk
              </td>
              <td>
                Investor
              </td>
              <td>

                Investor
              </td>
            </tr>

            <tr>
              <td>Investment Themes
              </td>
              <td>
                Debt or Equity or Balanced. Thematic based on scheme investment thesis
              </td>
              <td>
                Purely debt. Investor can choose individual loans or portfolios with low, moderate
                or high risk
                exposure based on their risk appetite
              </td>
            </tr>
            </tbody>
            <tbody class="show-more" [hidden]='!showMore'>
            <tr>
              <td>Performance Tracking
              </td>
              <td>
                NAV published on daily basis and monthly fact sheets
              </td>
              <td>
                Real time dashboard for Investors to track portfolio performance. Delay and NPA data
                updated on
                a daily basis.
              </td>
            </tr>

            <tr>
              <td>Minimum Investment Limit
              </td>
              <td>
                No/Very Low Limit
              </td>
              <td>
                Platforms have different minimum investment amount based on positioning.
              </td>
            </tr>
            <tr>
              <td>Maximum Investment Limit
              </td>
              <td>
                No Limit
              </td>
              <td>
                RBI has capped maximum investment in one loan at INR ₹50,000 and ₹5lacs across P2P
                platforms. Platforms follow their own algorithm within the cap to limit
                concentration into a few loans. Investment in a loan can be between ₹500 to ₹50,000
              </td>
            </tr>
            <tr>
              <td>
                Exit

              </td>
              <td>
                Open ended schemes: As and when needed
                Close ended/FMPs: Only on maturity of scheme
              </td>
              <td>
                Returns come in form of EMIs – combination of principal and interest. Investor can
                take the monthly EMI to bank account or reinvest only principal, only interest or
                both
              </td>
            </tr>
            <tr>
              <td>
                Liquidity

              </td>
              <td>
                Open ended schemes can be liquidated on T+2 or T+3 days basis
              </td>
              <td>
                Returns come on monthly basis and investment in completely repaid at the end of
                tenure. Some platforms offer liquidity on best effort basis, provided they can find
                a buyer for underlying loans
              </td>
            </tr>
            <tr>
              <td>
                Taxation
              </td>
              <td>
                Capital Gains. Rules differ based on Short Or Long term for Debt/Equity Schemes. Tax
                free to marginal rate of tax applicable
              </td>
              <td>
                Interest income is taxed as Income from Other Sources at marginal rate of tax
                applicable
              </td>
            </tr>
            <tr>
              <td>
                Expected Returns
              </td>
              <td>
                Debt: 5.5% to 8.0% IRR Equity: Market Linked – Historically long term median range
                between 6% to 12% IRR
              </td>
              <td>
                Average net of default for moderate risk portfolio returns 12% - 16% IRR
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div [ngClass]="{showingLess:!showMore}">
        </div>
        <button class="btn btn-custom" [ngClass]="{showmore:!showMore}"
                (click)='toggleComparison(comparisonTarget)'>{{showText}}</button>
      </div>
    </div>
  </div>
</section>
<section class="what-is-p2p py-100">
  <div class="container">
    <div class="sec-heading text-center">
      <h2>We have partnered with <span>Finzy</span> to bring this powerful asset class to you
      </h2>
      <h6>finzy is India's premier peer to peer lending portal. finzy digitally connects investors to hand-picked
        Borrowers to provide risk optimised returns that are as high as 2.5 times of bank Fixed Deposit on an
        average.</h6>
    </div>
    <div class="row">
      <div class="col-md-4 wow fadeInLeft">
        <div class="content-item-3 pl-0">
          <div class="item-head">
            <img src="assets/images/icons/about-finzy/finzy-is-an-rbi-regulated-nbfc-p-2-p.svg"
                 class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Finzy is RBI regulated<br>
              NBFC – P2P</h3>
          </div>
          <p>Finzy was more than 95% compliant with Reserve Bank of India’s Master Direction on NBFC - P2P on
            DAY 1 when published on October 04, 2017. Finzy was one of the first P2P platforms to receive
            the NBFC-P2P licence on June 29, 2018.
          </p>
        </div>
      </div>
      <div class="col-md-4 wow fadeInUp">
        <div class="content-item-3">
          <div class="item-head">
            <img src="assets/images/icons/about-finzy/strong-management.svg"
                 class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Strong<br>
              Management</h3>
          </div>
          <p>Finzy’s core team has cross functional experience of more than 75 years across the functions of
            Finance, Technology, Product, Sales, Marketing & Distribution and ably supported by a strong
            team of more than 100 inspired on-roll professionals.
          </p>
        </div>
      </div>
      <div class="col-md-4 wow fadeInRight">
        <div class="content-item-3 pr-0 mb-0">
          <div class="item-head">
            <img src="assets/images/icons/about-finzy/stellar-track-record.svg"
                 class="img-fluid d-inline-block">
            <h3 class="d-inline-block">Stellar Track<br>
              record</h3>
          </div>
          <p>Finzy maintained an unheard distinction in retail finance of Zero NPA for first 23 months till
            May 2019. The current NPAs are still one of the lowest in the country with less than 1.5% as of
            Feb 2020. Platform rejection rate of >85% with only deserving and eligible Borrowers loan being
            sanctioned.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="how-much-returns py-100 bg-grey">
  <div class="container ">
    <div class="sec-heading text-center">
      <h2>What return can I expect
      </h2>
      <h6>Finzy offers return ranging from 10.99% p.a to 21.99% p.a with an indicative average of around 15.5%
        depending upon availability of loans across finzy ratings.</h6>
    </div>
    <div class="row">
      <div class="col-lg-5 wow fadeInLeft">
        <div class='quote-section'>
          <div class="quote-from">
            <img src="assets/images/icons/returns/albert-einstein.png" class="img-fluid d-inline-block">
          </div>
          <div class="quote">
            Compound interest is the eighth <span class="lb"></span>wonder of the world - <span>Albert
                            einstein</span>
          </div>
        </div>
        <div class="text-content">
          <h3>Difference in 10% and 15% is not 5% but almost 649% of absolute returns in long run</h3>
          <p>P2P lending not only gives you monthly returns but also offers the unique options of reinvesting
            these monthly returns</p>
          <p>Its like subscribing to a monthly SIP without any fresh payment from your side</p>
        </div>
      </div>
      <div class="col-lg-7 wow fadeInRight">
        <div class="investment-summary">
          <h5>Value of <span>Rs 10,000 invested every month</span> at the end of <span>25 years</span> at the
            achieved historical rate of return (refers to Principal investment of <span>Rs 30 lacs</span>)
          </h5>
          <div class='wow' [chart]="chart"></div>

        </div>

      </div>
    </div>
  </div>

</section>
<section class="what-finzy-does py-100">
  <div class="container">
    <div class="sec-heading text-center">
      <h2>What Finzy Does for you
      </h2>
      <h6>Finzy is not a matchmaking platform but a complete Loan Lifecycle Management Company
      </h6>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row timeline-section">
          <img src='assets/images/icons/timeline/path-10.svg' class="img-fluid timeline-path">
          <div class="timeline-item col top">
            <div class='content c1 text-center wow fadeInDown'>
              <h5>Sourcing</h5>
              <img src='assets/images/icons/timeline/sourcing.svg' class="img-fluid">
            </div>
            <div class="dot d1 wow zoomIn"></div>
          </div>
          <div class="timeline-item col bottom">
            <div class='content c2  text-center wow fadeInUp'>
              <img src='assets/images/icons/timeline/verification.svg' class="img-fluid">
              <h5>Verification</h5>
            </div>
            <div class="dot d2 wow zoomIn"></div>
          </div>
          <div class="timeline-item col top">
            <div class='content c3 text-center wow fadeInDown'>
              <h5>Analysis</h5>
              <img src='assets/images/icons/timeline/analysis.svg' class="img-fluid">
            </div>
            <div class="dot d3 wow zoomIn"></div>
          </div>
          <div class="timeline-item col bottom">
            <div class='content c4  text-center wow fadeInUp'>
              <img src='assets/images/icons/timeline/investment.svg' class="img-fluid">
              <h5>Investment</h5>
            </div>
            <div class="dot d4 wow zoomIn"></div>
          </div>
          <div class="timeline-item col top ">
            <div class='content c5 text-center wow fadeInDown'>
              <h5>Documentation</h5>
              <img src='assets/images/icons/timeline/documentation.svg' class="img-fluid">
            </div>
            <div class="dot d5 wow zoomIn"></div>
          </div>
          <div class="timeline-item col bottom">
            <div class='content c6  text-center wow fadeInUp'>
              <img src='assets/images/icons/timeline/emi-collection.svg' class="img-fluid">
              <h5>EMI Collection</h5>
            </div>
            <div class="dot d6 wow zoomIn"></div>
          </div>
          <div class="timeline-item col top">
            <div class='content c7 text-center wow fadeInDown'>
              <h5>Reinvestment</h5>
              <img src='assets/images/icons/timeline/reinvestment.svg' class="img-fluid">
            </div>
            <div class="dot d7 wow zoomIn"></div>
          </div>
          <div class="timeline-item col bottom">
            <div class='content c8  text-center wow fadeInUp'>
              <img src='assets/images/icons/timeline/recovery.svg' class="img-fluid">
              <h5>Recovery</h5>
            </div>
            <div class="dot d8 wow zoomIn"></div>
          </div>
        </div>
        <div class="row mob-display">
          <div class="col-md-12">
            <img src='assets/images/icons/timeline/time-line.png'>
          </div>
        </div>
      </div>
    </div>
    <h4>Finzy Manages the entire lifecycle of a loan end to end.
    </h4>
  </div>
</section>
<section *ngIf="webinarSectionEnabled()" class="get-started py-100 bg-grey">
  <div class="container">
    <div class="sec-heading text-center">
      <h2>How do you get started
      </h2>
    </div>
    <div class='row'>
      <div class="col-md-4 text-left">
        <div class="card">
          <div class="image">
            <img src="assets/images/icons/get-started/pig.svg" class="img-fluid i1">
          </div>
          <h4>Click here to <span>start investing</span> in P2P lending with finzy </h4>
        </div>
      </div>
      <div class="col-md-4 text-left">
        <div class="card">
          <div class="image">

            <img src="assets/images/icons/get-started/logo.svg" class="img-fluid i2">
          </div>
          <h4>Click here to <span>watch webinars</span> about Finzy’s P2P Lending model </h4>
        </div>
      </div>
      <div class="col-md-4 text-left">
        <div class="card">
          <div class="image">

            <img src="assets/images/icons/get-started/phone.svg" class="img-fluid i3">
          </div>
          <h4>Click here to <span>get a callback from </span> us for more information</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="cta py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h2>Start investing with finzy</h2>
        <p>An opportunity to invest in a powerful new asset class by connecting you with the right borrowers. You earn monthly returns by investing in personal loans that are risk optimised.
        </p>
      </div>
      <div class="col-md-4 text-md-right text-center">
        <a href="{{partner.investmentUrl}}" class="btn btn-custom">Begin Investing</a>
      </div>
    </div>
  </div>
</section>
<section class="contact py-100 bg-grey">
  <div class="container">
    <div class="row h-100" style="justify-content: center;align-items: center;">
      <div *ngIf="enableCustomerForm()" class="col-lg-5 contact-us-item">
        <app-customer-contact-form [partner]=partner></app-customer-contact-form>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-6 my-auto contact-us-item">
        <div class="contact-details">
          <h3>Want to learn more about this exciting new asset class? Write to us</h3>
          <h2>We will get back to you</h2>
          <h4>Call us : {{partner.phone}}</h4>
          <h4>Email us : <a href="mailto:{{partner.email}}?subject=I would like to know more on Finzy P2P investments.">{{partner.email}}</a></h4>
        </div>
      </div>
    </div>
  </div>
</section>
