import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-comparison-popup',
  templateUrl: './comparison-popup.component.html',
  styleUrls: ['./comparison-popup.component.scss']
})
export class ComparisonPopupComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}
 


  ngOnInit() {
  }

}
