<div class="contact">
  <div class="form">
    <form ngNoForm method="POST" [action]="getActionUrl()">
      <div class="form-group">
        <label>Full name *</label>
        <input type="text" class="form-control" placeholder="Enter your full name"
               name='Name' required>
      </div>
      <div class="form-group">
        <label>Email Id *</label>
        <input type="email" class="form-control" placeholder="Enter your mail id"
               name='Email'
               required>
      </div>
      <div class="form-group">
        <label>Mobile Number *</label>
        <input type="tel" class="form-control" placeholder="Enter your mobile number"
               name='Mobile'
               required>
      </div>
      <div class="form-group">
        <label>Convenient Time*</label>
        <select type="text" class="form-control" placeholder="Select your convenient time slot with AM/PM"
               name='Time'
               required>
               <option value="12:00 AM">12:00 AM </option>
               <option value="1:00 AM">1:00 AM </option>
               <option value="2:00 AM">2:00 AM </option>
               <option value="3:00 AM">3:00 AM </option>
               <option value="4:00 AM">4:00 AM </option>
               <option value="5:00 AM">5:00 AM </option>
               <option value="6:00 AM">6:00 AM </option>
               <option value="7:00 AM">7:00 AM </option>
               <option value="8:00 AM">8:00 AM </option>
               <option value="9:00 AM">9:00 AM </option>
               <option value="10:00 AM">10:00 AM </option>
               <option value="11:00 AM">11:00 AM </option>
               <option value="12:00 PM">12:00 PM </option>
               <option value="1:00 PM">1:00 PM </option>
              <option value="2:00 PM">2:00 PM </option>
              <option value="3:00 PM">3:00 PM </option>
              <option value="4:00 PM">4:00 PM </option>
              <option value="5:00 PM">5:00 PM </option>
              <option value="6:00 PM">6:00 PM </option>
              <option value="7:00 PM">7:00 PM </option>
              <option value="8:00 PM">8:00 PM </option>
              <option value="9:00 PM">9:00 PM </option>
              <option value="10:00 PM">10:00 PM </option>
              <option value="11:00 PM">11:00 PM </option>
        </select>
      </div>
      <button class="btn btn-custom btn-custom-inverse" type="submit">SUBMIT</button>
    </form>
  </div>
</div>
